import logo from './logo.svg';
import './App.css';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
    <div className="App">
      <header className="App-header">
      
        <p>
          
          <Button as="a" variant="primary" href="https://www.google.com">Download Latest Stable Build</Button>
          <br/>
          <Button variant="secondary">Download Certificate</Button>
        </p>
      </header>
    </div>
  );
}

export default App;
